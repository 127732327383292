import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Link } from 'gatsby'

// import Video from '../components/Video'
import Layout from '../components/Layout'

class About extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )

    return (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`Contact | ${siteTitle}`}
        />

        {/* <h2>A bit about me:</h2> */}
        {/* <br /> */}
        <p>
 
            {/* I also now know a ton about the GI tract;
            if you're over 50, I urge you to get your colonoscopy!  */}
            {/* CRC is one of the most preventable cancers in the world, <b>IF</b> you stay on top of your checkups. */}
        </p>
        <p>
            {/* <b>My background:</b><br/> */}
            {/* I started off at Intel building ASICs for a novel memory design. */}
            {/* Couple of years into that, I got a chance to go abroad for a year to Germany, which was a experience.  */}
        </p>
        {/* <p>
            Hi there!
          </p> */}
          {/* <h3>Hi there!</h3> */}

          <h4>What's my background?</h4>
          <p>
            I started my career off doing hardware design.
            Couple of years into that, I got an opportunity to go abroad and do some web development for a medical education startup (<a href="www.amboss.com" target="_blank">Amboss</a>) in Germany.
        </p>
        <p>
            After returning home, I got the startup bug and joined a company developing AI for gastroenterology;
            check it out <a href="https://www.docbot.co" target="_blank">here!</a> 
            </p>
            <p>
            {/* The goal is to deliver better patient care at lower costs, thereby serving all three of the P's (Patients, Payers, Providers). 
            A true win-win. */}
            {/* When you get up close and personal to the 3 P's, 
            you are able to start getting a feel for just how complex the healthcare system is in the US.  */}
            {/* I'm looking for a new opportunity now, however.  */}
            {/* The beauty is though that change is coming, albeit slowly.
            There is tech being built today to provide better patient outcomes, reduce costs, and alleviate doctor burn-out. 
            To make it in healthcare, and truly fix it, one must figure out a way to align incentives amongst incumbents... */}
        </p>
        <h4>What are my skills?</h4>
        <p>
            On the technical end, I've worked on full stack, data engineering, computer vision, Linux dev, and cloud architecture. 
        <br/>
        <br/>           
            On the strategic front, I've done product management, and customer and partner outreach/management/success. This has included digital advertising, product demos, presentations, conferences, blogs, distributor relationships, and more.
        </p>
        
        <h4>What's the 10-second elevator pitch?</h4>
        <p>
          If you're looking for a "been-around-the-block and likes to get his hands dirty" type of profile, I'm the guy!
        </p>

        <h4>What am I like?</h4>
        <p>
          I'm pretty affable- I like to engage warmly with the people I work with.
          Outside of work, I try to get outdoors (basketball/hiking anyone?) or read up on interests in healthcare, technology, history, psychology, and more.
        </p>

        {/* <p>
            I've amassed quite a bit of experience in software development 
        </p> */}
        {/* <p>
            I'm pretty amicable and friendly. 
        </p> */}  
        
        {/* <p>
            Anyways, enough about that; here's me 10+ years ago experiencing my 15 minutes of fame:
        </p>
        <Video
            videoSrcURL="https://youtu.be/OH0cKOMNKFk"
            //videoSrcURL="https://www.youtube.com/embed/videoseries?list=PLx0sYbCqOb8TBPRdmBHs5Iftvv9TPboYG"
            videoTitle ="My 15 Minutes of Fame"
        /> */}

        
      </Layout>
    )
  }
}

export default About

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
